import React from "react"
import {graphql} from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PodcastListItem from '../components/podcast-list-item'
import Pagination from '../components/pagination'

const ShowsList = ({ pageContext, data, location }) => {
  const {
    breadcrumb: { crumbs },
    currentPage,
    numPages
  } = pageContext

  return (
    <Layout>
      <SEO
          title="Infos locales"
          description="Retrouvez la liste de nos infos locales."
      />
      <div id="podcasts-list" className="container list-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel="Infos locales"
          />

          <h1 className="title is-1">Infos locales</h1>
        </section>

        <section className="section page-content">
          <div className="columns is-multiline">
            {data.allNodePodcast.edges.map(({ node }, index) => (
              <PodcastListItem node={node} key={index} columnType={6} columnTypeWidescreen={4} />
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </section>
      </div>
    </Layout>
  )
}

export default ShowsList

export const query = graphql`
query podcastListQuery($skip: Int!, $limit: Int!) {
  allNodePodcast(
    filter: {status: {eq: true}}, 
    sort: {fields: created, order: DESC},
    limit: $limit, 
    skip: $skip
  ) {
    edges {
      node {
        id
        title
        field_duree {
          seconds
        }
        created(formatString: "DD MMMM YYYY", locale: "fr")
        path {
          alias
        }
        relationships {
          image: field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 260, maxHeight: 180, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
                sizes {
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          audio: field_audio {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
}`
